






import { Component, Vue, Provide } from 'vue-property-decorator';
import QuestionProvider from '@/providers/questions.provider';
import CourseList from '../../shared/components/CourseList.component.vue';
import { APPIDS, RESERVE_EXAM_ROUTE_NAME } from '../../enums';

@Component({
  components: { CourseList },
})
export default class ReserveExamCourseList extends Vue {
  @Provide() Provider = QuestionProvider;

  appId = APPIDS.RESERVE_EXAM;

  name = 'ReserveExamCourseList';

  goToReserveExamsList(courseData) {
    this.$router.push({
      name: RESERVE_EXAM_ROUTE_NAME.RESERVE_EXAMS_LIST,
      params: {
        courseId: courseData.courseId.toString(),
      },
    });
  }
}
